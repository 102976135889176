<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="section" v-if="!$store.state.is_trainer_package_subscriber">
        <i class="fa-solid fa-crown" style="color: #FFD43B;"></i>
        <h2>Introducing the MSM Trainer/Breeder Package!</h2>
        <p>We are offering unlimited "{{ plans.basic.name }}" listings (${{ plans.basic.prices.monthly.price }} individually) for just ${{ plans.trainer_package.prices.monthly.price }} per month. The basic listing includes an ad with a map pin of the horse's location as well as inclusion on the "View All" list option. The horse's ad will also be shown on the iOS app and Android. All horses are promoted on Mane Street Market's social media.</p>
        <p>Questions: <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a></p>
        <div class="card box-shadow package-card">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{ plans.trainer_package.name }}</h4>
          </div>
          <div class="card-body">
            <div>
              <h3 class="card-title pricing-card-title">${{ plans.trainer_package.prices.monthly.price }}</h3>
              <h3 class="card-title pricing-card-title">PER MONTH</h3>
            </div>
            <p>
              The {{ plans.trainer_package.name }} is ideal for trainers who consistently have 5 or more horses/equipment for sale each month. You can add as many horses/equipment as you wish and delete the sold horses/equipment as needed.
            </p>
          </div>
          <div class="card-footer">
            <router-link :to="{name: 'subscribe', params: {id: plans.trainer_package.code}, query: {next_page: 'trainer-package', next_id: 'trainer-package'}}" type="button" class="btn btn-primary">Subscribe</router-link>
          </div>
        </div>
      </div>
      <div class="section" v-else>
        <i class="fa-solid fa-crown" style="color: #FFD43B;"></i>
        <h2>Welcome to the MSM Trainer/Breeder Package!</h2>
        <ul>
          <li>You will now have access to post unlimited horse/equipment listings under the "{{ plans.basic.name }}" price plan.</li>
          <li>Click on "List/Edit/Delete Horse Listings" or "List/Edit/Delete Equipment Listings" then "Add New Listing" and follow the prompts.</li>
          <li>When you get to the "Choose your Price Plan" choose the "{{ plans.basic.name }}" plan for ${{ plans.basic.prices.monthly.price }} - you will not be charged. If you choose the "{{ plans.regional.name }}" you will be charged an additional ${{ plans.regional.prices.monthly.price }} for that listing.</li>
          <li>When a horse/equipment sells - please delete that listing through the Manage Listings dropdown. You will see "Delete" as an option.</li>
          <li>Any questions - please email <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a></li>
        </ul>
        <div class="row">
          <router-link to="/my-listings" type="button" class="btn btn-primary">List/Edit/Delete Horse Listings</router-link>
          <router-link to="/my-equipment" type="button" class="btn btn-primary">List/Edit/Delete Equipment Listings</router-link>
          <router-link to="/my-payment-methods" type="button" class="btn btn-primary">Update Payment Method</router-link>
        </div>
      </div>
      <div class="section">
        <h2>FAQ</h2>
        <ACollapse accordion class="faq-collapsibles">
          <APanel header="I currently have multiple basic horse/equipment listings - what happens to them if I subscribe to the Trainer/Breeder Package?" key="1">
            <p>Your listings will merge into the Trainer/Breeder Package program for the next billing cycle.</p>
          </APanel>
          <APanel header="What are the benefits of choosing the Trainer/Breeder Package?" key="2">
            <p>If you consistently have multiple horses/equipment for sale - this package is for you. It allows you to list unlimited horses/equipment for one fee of ${{ plans.trainer_package.prices.monthly.price }}. You can treat Mane Street Market as your personal/professional marketing site as we will get more eyes on all of your horses outside of your current network.</p>
          </APanel>
          <APanel header="What if I decide to cancel my Trainer/Breeder Package subscription?" key="3">
            <p>You will have the choice to delete all of your listings at the time you delete your Trainer/Breeder Package subscription or you can choose to list and pay for each horse/equipment individually.</p>
          </APanel>
          <APanel header="I currently have a premium listing - how can I change that to a Trainer/Breeder Package?" key="4">
            <p>Please email <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a></p>
          </APanel>
        </ACollapse>
      </div>
      <div class="section cta-section" v-if="!$store.state.is_trainer_package_subscriber">
        <h2>Ready To Get Started?</h2>
        <p>Click here to subscribe to the MSM Trainer/Breeder Package:</p>
        <router-link :to="{name: 'subscribe', params: {id: plans.trainer_package.code}, query: {next_page: 'trainer-package', next_id: 'trainer-package'}}" type="button" class="btn btn-primary">Subscribe</router-link>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import ACollapse from 'ant-design-vue/lib/collapse';
import 'ant-design-vue/lib/collapse/style/index.css';
import AButton from 'ant-design-vue/lib/button';
import 'ant-design-vue/lib/button/style/index.css';
import plans from '@/constants/plans';
import AModal from 'ant-design-vue/lib/modal';
import 'ant-design-vue/lib/modal/style/index.css';
import axios from 'axios';
import Swal from "sweetalert2";
import { db } from "@/firebase";

export default {
  components: {
    BaseLayout,
    SubHeader,
    ACollapse,
    APanel: ACollapse.Panel,
    AButton,
    AModal,
  },
  data() {
    return {
      plans,
    }
  },
  mounted() {
    document.title = 'Trainer/Breeder Package - Mane Street Market';
    window.scrollTo(0, 0);
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 1.6rem;
    color: #051548;
    margin: 0;
  }

  h4 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 800px;
    max-width: 100%;
    margin: auto;
    gap: 16px;

    ul {
      text-align: left;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .package-card {
    max-width: 400px;
  }

  .faq-collapsibles {
    width: 100%;
    max-width: 800px;
  }

  .cta-section {
    background-color: #F6F6F6;
    border-radius: 8px;
    padding: 32px;
  }

  .row {
    gap: 8px;
  }
</style>
